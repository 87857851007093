import { RouteComponentProps } from "react-router-dom";

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  apiCall,
  errorNotification,
  getLocalState,
  setLocalState,
  successNotification,
} from "../../studio-store-restaurant-components/src/Utility.web";
import { createRef, RefObject } from "react";
import { FormikProps } from "formik";
import {
  OrderResponseType,
  OrderItemType
} from "../../studio-store-restaurant-utilities/src/types/ShoppingCartTypes";
import {
  BannerResponseType,
  ImageType,
  CouponCodeType,
  OrderItemOptionsType,
} from "../../studio-store-restaurant-utilities/src/types/CommonTypes";
export const configJSON = require("./config.js");
// Customizable Area Start
// Customizable Area End

export interface Props extends RouteComponentProps {
  // Customizable Area Start
  classes: Record<string, string>;
  myCartList: OrderResponseType;
  getProducts: () => void;
  getCarts: () => void;


  // Customizable Area End
}

interface S {
  // Customizable Area Start
  bannerImgList: ImageType[];
  allCoupons: CouponCodeType[];
  showAllCoupons?: boolean;
  loader: boolean;
  couponCopied: string | number;
  itemQuantity: number;
  couponCode: string;
  rewardsQuantity: number;
  codeEmpty: boolean;
  rewardIsEmpty: boolean;
  codeErrorContent: string;
  toggleRewards: boolean;
  //for remove cartItem states
  isCartItemRemove: boolean;
  cartItemId: string;
  rewardContent: string;
  isRewardEnable: boolean;
  isCouponEnable: boolean;
  isLoggedIn: boolean;
  isActivated?: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCartAPICallId: string = "";
  getAllCouponsAPICallId: string = "";
  updateCartApiCallId: string = "";
  removeCartItemsApiCallId: string = "";
  applyCouponApiCallId: string = "";
  removeCouponApiCallId: string = "";
  applyRewardsApiCallId: string = "";
  removeRewardsApiCallId: string = "";
  formRef: RefObject<FormikProps<{}>>;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.BannerListApiMessage),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      bannerImgList: [],
      allCoupons: [],
      loader: false,
      showAllCoupons: false,
      couponCopied: "",
      rewardsQuantity: Number(
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance
      ),
      itemQuantity: 0,
      couponCode:
        this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes
          ?.code || "",
      codeErrorContent: "",
      codeEmpty: false,
      rewardIsEmpty: false,
      toggleRewards: false,
      isRewardEnable: Boolean(
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance
      ),
      isCouponEnable: Boolean(
        this.props?.myCartList?.order?.data?.attributes?.coupon_code_id
      ),
      isLoggedIn: false,
      isCartItemRemove: false,
      cartItemId: "",
      rewardContent: "",
      isActivated: false
      // Customizable Area End
    };

    this.formRef = createRef();
  }

  // Customizable Area Start
  getResponseVariables = (message: Message) => {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    return [responseJSON, apiRequestCallID];
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps) {
      this.setState({
        couponCode:
          nextProps?.myCartList?.order?.data?.attributes?.coupon &&
          nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes
            ?.code
            ? nextProps?.myCartList?.order?.data?.attributes?.coupon?.attributes
                ?.code
            : "",
        isCouponEnable: Boolean(
          nextProps?.myCartList?.order?.data?.attributes?.coupon_code_id
        ),
        rewardsQuantity: Number(
          nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance
        ),
        isRewardEnable: Boolean(
          nextProps?.myCartList?.order?.data?.attributes?.used_reward_balance
        ),
      });
      const userData = getLocalState("userData");
      const userCompletedInfo = getLocalState("userCompleteInfo");
      const registartionNotCompleted = getLocalState("registrationDetails");
      const isGuest = getLocalState("guestUserID");
      if (userData && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (isGuest) {
        this.setState({
          isLoggedIn: false,
        });
      }
      if (isGuest && userCompletedInfo) {
        this.setState({
          isLoggedIn: true,
        });
      }
      if (userData && registartionNotCompleted) {
        this.setState({
          isLoggedIn: false,
        });
      }
    }
  }
  // Customizable Area End

  async componentDidMount() {
    // Customizable Area Start
    const userData = getLocalState("userData");
    const userCompletedInfo = getLocalState("userCompleteInfo");
    if (userData) {
      this.setState({
        isLoggedIn: true,
      });
    }

    if (userCompletedInfo) {
      this.setState({
        isActivated: userCompletedInfo?.attributes?.activated,
      });
    }

    this.setState({
      rewardsQuantity: Number(
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance
      ),
      isRewardEnable: Boolean(
        this.props?.myCartList?.order?.data?.attributes?.used_reward_balance
      ),
      couponCode:
        this.props?.myCartList?.order?.data?.attributes?.coupon?.attributes
          ?.code || "",
      isCouponEnable: Boolean(
        this.props?.myCartList?.order?.data?.attributes?.coupon_code_id
      ),
    });
    this.getCoupons();
    // Customizable Area End
  }

  // Customizable Area Start
  removeProductModalClose = () => {
    this.setState({
      isCartItemRemove: !this.state.isCartItemRemove,
    });
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ loader: false });
    if (message.id === getName(MessageEnum.BannerListApiMessage)) {
      const bannerListData = message.getData(
        getName(MessageEnum.BannerListApiResponseMessage)
      );
      if (bannerListData) {
        this.bannerImgListResponce(bannerListData);
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isValidResponse(message)) {
        this.apiSuccessCallBacks(message);
      }
      if (this.isInValidResponse(message)) {
        this.apiFailCallBacks(message);
      }
      if (errorMessage) {
        this.setState({ loader: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (message: Message) => {
    const [responseJson] = this.getResponseVariables(message);
    return responseJson && responseJson.data;
  };

  isInValidResponse = (message: Message) => {
    const [responseJSON] = this.getResponseVariables(message);
    return (
      responseJSON && responseJSON.errors && responseJSON.errors.length > 0
    );
  };

  apiSuccessCallBacks = (message: Message) => {
    const [responseJSON, apiRequestCallID] = this.getResponseVariables(message);
    //get all coupons
    if (apiRequestCallID === this.getAllCouponsAPICallId) {
      this.setState({
        allCoupons: responseJSON.data,
      });
    }

    //update cart
    if (apiRequestCallID === this.updateCartApiCallId) {
      this.setState({ itemQuantity: 0, isCartItemRemove: false });
      successNotification("Cart has been Updated.");
      this.props?.getProducts();
      this.props?.getCarts();
    }

    if (apiRequestCallID === this.removeCartItemsApiCallId) {
      this.setState({ itemQuantity: 0, isCartItemRemove: false });
      successNotification("Items Remove from cart.");
      const cartDataLength = parseInt(getLocalState("cartDataLen") ?? "0");
      setLocalState("cartDataLen", cartDataLength - 1);
      let cartListUpdateMessage = new Message(
        getName(MessageEnum.UpdateCartListApiMessage)
      );
      cartListUpdateMessage.addData(
        getName(MessageEnum.UpdateCartListApiResponseMessage),
        cartDataLength - 1
      );
      runEngine.sendMessage(cartListUpdateMessage.id, cartListUpdateMessage);
      this.props?.getProducts();
      this.props?.getCarts();
    }

    //coupon apply
    if (apiRequestCallID === this.applyCouponApiCallId) {
      this.setState({ itemQuantity: 0, loader: false });
      successNotification("Coupon successfully applied.");
      this.props?.getCarts();
    }

    //remove coupon
    if (apiRequestCallID === this.removeCouponApiCallId) {
      this.setState({
        loader: false,
        toggleRewards: false,
        couponCode: "",
      });

      successNotification("Coupon removed successfully.");
      this.props?.getCarts();
    }

    //apply rewards
    if (apiRequestCallID === this.applyRewardsApiCallId) {
      this.setState({
        itemQuantity: 0,
        loader: false,
        rewardContent: "",
        isRewardEnable: false,
      });
      successNotification("Rewards successfully applied.");
      this.props?.getCarts();
    }

    //remove rewards
    if (apiRequestCallID === this.removeRewardsApiCallId) {
      this.setState({
        loader: false,
        toggleRewards: false,
        couponCode: "",
        rewardContent: "",
      });

      successNotification("Rewards Removed Successfully.");
      this.props?.getCarts();
    }
  };

  apiFailCallBacks = (message: Message) => {
    const [responseJSON, apiRequestCallID] = this.getResponseVariables(message);
    if (apiRequestCallID === this.applyRewardsApiCallId) {
      this.setState({
        codeErrorContent: responseJSON.errors[0]?.message,
        isRewardEnable: true,
        loader: false,
      });
    }
    if (apiRequestCallID === this.applyCouponApiCallId) {
      this.setState({
        loader: false,
      });
      errorNotification(responseJSON.errors[0].message);
    }
    if (apiRequestCallID === this.updateCartApiCallId) {
      errorNotification(responseJSON.errors[0].order);
    }
  };

  //  START -------------------------------------------------------------- Banner Img List 
  bannerImgListResponce = (apiResData: BannerResponseType[]) => {
    const bannerData = apiResData?.filter(
      (banner: { attributes: { banner_position: number } }) =>
        banner.attributes?.banner_position === 2 ||
        banner.attributes?.banner_position === 3
    );
    const bannerDataImg = bannerData?.map(
      (banner) => banner.attributes?.images
    );
    let bannerList: ImageType[] = [];
    for (let banList = 0; banList < bannerDataImg?.length; banList++) {
      bannerList.push(bannerDataImg[banList]?.data[0]?.attributes);
    }
    this.setState({ bannerImgList: bannerList });
  };
  // END -------------------------------------------------------------- Banner Img List 

  //toggle View coupons Modal
  toggleCouponModal = () => {
    this.setState({
      showAllCoupons: !this.state.showAllCoupons,
    });
  };

  //get all coupons
  getCoupons = async () => {
    this.getAllCouponsAPICallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCouponsListAPIEndPoint,
    });
  };

  //copy coupon
  copyCoupon = (coupon: CouponCodeType, index: number) => {
    this.setState({
      couponCopied: index,
      couponCode: coupon?.attributes?.code,
      codeEmpty: false,
      codeErrorContent: "",
      showAllCoupons: false,
    });
    setTimeout(() => {
      this.applyCoupon(this.props?.myCartList?.order?.data);
    }, 1000);
  };

  //updateCart
  updateCartQuantity = (product: OrderItemType, action: string) => {
    if (action === "Add") {
      this.setState({
        itemQuantity:
          product?.attributes?.quantity + this.state.itemQuantity + 1,
      });
    } else if (action === "Subtract") {
      this.setState({
        itemQuantity:
          product?.attributes?.quantity + this.state.itemQuantity - 1,
      });
    }

    setTimeout(async () => {
      let subOrderId = product?.attributes?.order_item_options.map((item) => {
        return {
          catalogue_attribute_value_id:
            item?.attributes?.catalogue_attribute_value_id,
          id: item?.attributes?.id,
        };
      });
      let httpBody;
      if (subOrderId?.length > 0) {
        httpBody = {
          order_item_id: parseInt(product?.id, 10),
          order_item_options_attributes: subOrderId,
          quantity: this.state.itemQuantity,
        };
      } else {
        httpBody = {
          order_item_id: parseInt(product?.id, 10),
          quantity: this.state.itemQuantity,
        };
      }

      this.updateCartApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: configJSON.orderBlockAPIEndpoint,
        body: httpBody,
      });
    }, 1000);
  };

  //apply Coupon
  applyCoupon = async (cartData: {
    attributes: { sub_total: string };
    id: string | number;
  }) => {
    this.setState({ codeEmpty: false, codeErrorContent: "", loader: true });
    if (this.state.couponCode.length > 0) {
      const httpBody = {
        code: this.state.couponCode,
        cart_value: parseFloat(cartData?.attributes?.sub_total),
      };

      this.applyCouponApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: `${configJSON.orderBlockAPIEndpoint}/${cartData?.id}/apply_coupon`,
        body: httpBody,
      });
    } else {
      this.setState({ codeEmpty: true, loader: false });
    }
  };

  //apply rewards
  applyReward = async () => {
    const cartData = this.props?.myCartList?.order?.data;
    let httpBody;
    if (this.state.rewardsQuantity === null) {
      this.setState({ codeErrorContent: `Rewards can't be empty` });
    } else if (
      this.state.rewardsQuantity <=
        this.props?.myCartList?.order?.data?.attributes?.reward_setting
          ?.max_reward_usage_limit ||
      this.state.rewardsQuantity === null
    ) {
      this.setState({ loader: true, isRewardEnable: false });

      if (
        this.props?.myCartList?.order?.data?.attributes?.reward_history_id !==
        null
      ) {
        httpBody = {
          cart_value: parseFloat(cartData?.attributes?.sub_total),
          cart_id: parseInt(cartData?.id, 10),
          used_reward: this.state.rewardsQuantity,
          reward_history_id: parseInt(
            String(cartData?.attributes?.reward_history_id)
          ),
        };
      } else {
        httpBody = {
          cart_value: parseFloat(cartData?.attributes?.sub_total),
          cart_id: parseInt(cartData?.id, 10),
          used_reward: this.state.rewardsQuantity,
        };
      }

      this.applyRewardsApiCallId = await apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiPostMethod,
        endPoint: configJSON.applyRewardsAPIEndPoint,
        body: httpBody,
      });
    } else if (
      this.state.couponCode?.length > 0 ||
      Number(this.state.couponCopied) <=
        this.props?.myCartList?.order?.data?.attributes?.reward_setting
          ?.max_reward_usage_limit
    ) {
      this.setState({
        codeErrorContent: "Enter Rewards points under Limit bar",
      });
    }
  };

  //remove Coupon
  removeCoupon = async (cartData: { id: string | number }) => {
    this.setState({ loader: true });

    this.removeCouponApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.orderBlockAPIEndpoint}/${cartData?.id}`,
    });
  };

  //remove from cart
  removeProductFromCart = async () => {
    this.setState({});

    const httpBody = {
      order_item_id: this.state.cartItemId,
      quantity: 0,
    };

    this.removeCartItemsApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.orderBlockAPIEndpoint,
      body: httpBody,
    });
  };

  //remove applied rewards
  removeRewards = async (cartData: { id: string | number }) => {
    this.setState({ loader: true });

    this.removeRewardsApiCallId = await apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiDeleteMethod,
      endPoint: `${configJSON.applyRewardsAPIEndPoint}/${cartData?.id}`,
    });
  };

  onHandleCustomizeItems = (value: OrderItemOptionsType[]) => {
    let itemCustomizeString = value?.map((item) => item?.attributes?.name);
    return itemCustomizeString?.join(", ");
  };

  removeCartItem = (itemId: string) => {
    this.setState({
      isCartItemRemove: true,
      cartItemId: itemId,
    });
  };

  couponCode = (myCartList: OrderResponseType) => {
    return myCartList?.order?.data?.attributes?.coupon?.attributes?.code;
  };

  openReward = () => {
    this.setState({ toggleRewards: true });
  };

  isNotCouponCode = (myCartList: OrderResponseType) => {
    return (
      this.state.toggleRewards &&
      myCartList?.order?.data?.attributes?.coupon_code_id === null
    );
  };

  closeReward = () => {
    this.setState({ toggleRewards: false });
  };

  rewardsQuantity = (
    event: React.ChangeEvent<HTMLInputElement>,
    myCartList: OrderResponseType
  ) => {
    let limitValue =
      myCartList?.order?.data?.attributes?.reward_setting
        ?.max_reward_usage_limit;
    if (limitValue < Number(event.target.value)) {
      errorNotification("Rewards Point Not Available");

      this.setState({
        rewardsQuantity: parseInt(event.target.value, 10),
        codeErrorContent: "",
      });
    } else {
      this.setState({
        rewardsQuantity: parseInt(event.target.value, 10),
        codeErrorContent: "",
      });
    }
  };

  isCouponCode = (myCartList: OrderResponseType) => {
    return (
      myCartList?.order?.data?.attributes?.reward_history_id &&
      myCartList?.order?.data?.attributes?.applied_reward_discount !== null &&
      !this.state.rewardContent
    );
  };

  isCouponEnable = () => {
    this.setState({
      isCouponEnable: true,
    });
  };

  changeCouponCode = (values: string) => {
    this.setState({
      couponCode: values,
      codeEmpty: false,
      codeErrorContent: "",
    });
  };
  // Customizable Area End
}
